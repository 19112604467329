import PropTypes from 'prop-types';


import styles from './index.styl';

function RecipeCard({ cardInfo }) {
  const { image, title, desc } = cardInfo;

  return (
    <a
      href='https://samokat.ru/'
      className={styles.cardLink}
      target='_blank'
      rel='noopener noreferrer'
    >
      <style jsx>{`
        .${styles.image}
          background-image url(${image})
      `}</style>
      <div className={styles.card}>
        <div className={styles.image} />
        <div className={styles.cardText}>
          <p className={styles.title}>{title}</p>
          {desc && <p className={styles.desc}>{desc}</p>}
        </div>
      </div>
    </a>
  );
}

RecipeCard.propTypes = {
  cardInfo: PropTypes.object,
};

export default RecipeCard;
