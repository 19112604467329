import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import withoutSSR from 'core/components/withoutSSR';

const LazyMascot = React.lazy(() => import('site/components/Mascot'));

const MascotWithHOCs = withoutSSR(props => (
  <Suspense fallback={<Fragment />}>
    <LazyMascot {...props} />
  </Suspense>
));

function Mascot({ isMobile, ...props }) {
  if (isMobile) return null;

  return <MascotWithHOCs {...props} />;
}

Mascot.propTypes = {
  isMobile: PropTypes.bool,
};


export default withBreakpoint(Mascot);
