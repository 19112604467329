import { getHardCodeTopic } from 'site/utils';

export const host = 'blog.okko.tv';
export const siteUrl = 'https://' + host;

export const basicSlug = '[a-z0-9-_]+';
export const topicSlug = '[a-z0-9-_.]+/[a-z0-9-_.]+';

export const OKKO_TV_URL = 'https://okko.tv/';

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 30;
export const VERTICAL_INDENT_MOBILE = 20;

export const datePattern = 'dd MMMM yyyy HH:mm';

export const SELECTIONS_SLUG = 'selections';
export const TABLE_OF_CONTENTS_TITLE = 'Содержание';

// amp topic pattern
export const TOPIC_AMP_PATTERN = {
  path: `/amp/:topic_link(${topicSlug})`,
};

const getOscarsPromo = (coverType) => {
  return getHardCodeTopic({
    headline: 'Все главное об «Оскаре-2025»',
    link: 'https://blog.okko.tv/oscars-2025',
    published_at: '2025-02-24T07:00:30.000Z',
    image: require('site/images/oscars.jpg'),
    coverType,
  });
};

export const promoMap = {
  'mainRead': getOscarsPromo('square'),
  '/reviews': getOscarsPromo(),
  '/redquiz': null,
  '/articles': getOscarsPromo(),
  '/selections': getOscarsPromo(),
  '/news': null,
};
