import { useState } from 'react';
import PropTypes from 'prop-types';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import Scroller from 'core/components/Scroller';

import RecipeCard from './RecipeCard';
import Okko from 'site/icons/Okko';
import SamokatLogo from '../images/Samokat';
import arrow from '../images/arrow.png';

import oladushki from '../images/recipeCard/oladushki.png';
import olivie from '../images/recipeCard/olivie.png';
import makaroni from '../images/recipeCard/makaroni.png';
import sup from '../images/recipeCard/sup.png';

import styles from './index.styl';


const termsLink = 'https://terms.samokat.ru/promo/pravila_samokat_mamonti.pdf';
const promocode = 'МАМОНТЫ';

const cards = [
  {
    image: oladushki,
    title: 'Оладушки',
    desc: 'Семейная порция',
  },
  {
    image: olivie,
    title: 'Оливье',
    desc: 'Семейная порция',
  },
  {
    image: makaroni,
    title: 'Макароны\nпо-флотски',
    desc: '',
  },
  {
    image: sup,
    title: 'Щавелевый\nсуп',
    desc: '',
  },

];


function Samokat({ isMobile }) {
  const [showBalloon, setShowBalloon] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(promocode).then(() => {
      setShowBalloon(true);
      setTimeout(() => setShowBalloon(false), 1500);
    }).catch(() => console.error('Ошибка копирования'));
  };

  const RecipeListWrapper = isMobile ? Scroller : 'div';
  return (
    <section className={styles.samokatWrapper}>
      <div className={styles.samokat}>
        <div className={styles.blockHeader}>

          <div className={styles.num}>5</div>
          {!isMobile && <img src={arrow} className={styles.arrow} />}
          <div className={styles.title}>Ешьте вместе</div>
          <div className={styles.desc}>
            <p>А со специальным меню от сервиса доставки «Самокат» и «Мамонтов» можно насладиться настоящими домашними блюдами, не тратя время на готовку.</p>
            <Desktop>
              <p>По промо-коду
                <span className={styles.promocodeButtonWrapper}>
                  {showBalloon && <div className={styles.balloon}>Скопировано</div>}
                  <button onClick={copyToClipboard} className={styles.promocode}>{promocode}</button>
                </span>
                — скидка 100р*</p>
              <p className={styles.terms}>*От заказа на 700р и выше. Подробные правила акции — <a
                href={termsLink}
                target='_blank'
                rel='noopener noreferrer'
              >по ссылке.</a></p>
            </Desktop>

            <Mobile>
              <div className={styles.promocodeWrapperMobile}>
                <p>Скидка 100р* по промокоду <br />
                  <span className={styles.promocodeButtonWrapper}>
                    {showBalloon && <div className={styles.balloon}>Скопировано</div>}
                    <button onClick={copyToClipboard} className={styles.promocode}>{promocode}</button>
                  </span>
                </p>
                <p className={styles.terms}>*От заказа на 700р и выше. Подробные правила акции — <a
                  href={termsLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >по ссылке.</a></p>
              </div>
            </Mobile>

          </div>
          <div className={styles.icons}>
            <Okko width={isMobile ? 33 : 66} height={isMobile ? 11 : 23} />
            <span className={styles.vs}>x</span>
            <SamokatLogo width={isMobile ? 76 : 153} height={isMobile ? 11 : 23} />
          </div>
        </div>

        <RecipeListWrapper className={styles.recipeList}>
          <div className={styles.recipeListScroll}>
            {cards.map(i => <RecipeCard cardInfo={i} key={i.title} />)}
          </div>
        </RecipeListWrapper>

      </div>
    </section>
  );
}

Samokat.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Samokat);
