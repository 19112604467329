import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';

import Modal from 'core/components/Modal';
import EmptyWrapper from 'core/components/EmptyWrapper';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import cards from './cards';

import Close from './images/Close.svg';

import styles from './index.styl';


const Bingo = ({ isMobile }) => {
  const [flippedCards, setFlippedCards] = useState(new Set());
  const [selectedCard, setSelectedCard] = useState(null);
  const modalRef = useRef(null);
  const closeModal = () => setSelectedCard(null);

  const handleClick = (index) => {
    if (isMobile) {
      setSelectedCard(index);
    } else {
      setFlippedCards((prev) => {
        const newSet = new Set(prev);
        newSet.add(index);
        return newSet;
      });
    }
  };

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .closeContainer
          top 10px
          right 10px
        .closeIcon
          width 24px
          height 24px
       .modalOverlay
          background-color rgba(0, 0, 0, 0.5)
        .modalWindow
          background-color #fff
          width 80%
          box-sizing border-box
          overflow visible
          border-radius 8px
      `}</style>
    </scope>
  );

  useEffect(() => {
    const isModalOpen = selectedCard !== null;

    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    const handleEscapeKeyDown = (event) => {
      if (isModalOpen && event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKeyDown);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [selectedCard]);

  const Wrapper = isMobile ? Modal : EmptyWrapper;

  return (
    <div className={styles.bingo}>
      {cards.map((card, index) => (
        <div
          className={cx(
            styles.card,
            flippedCards.has(index) && styles._flipped,
            (selectedCard === index) && styles._selected,
          )}
          onClick={() => handleClick(index)}
          key={index}
        >
          <div className={styles.inner}>
            <div className={styles.imageWrapper}>
              <img
                src={card.image}
                className={styles.image}
                alt='oscar'
              />
            </div>
            <Wrapper
              isOpen={selectedCard === index}
              onClose={handleClick}
              scopedStyles={scopedStyles}
              closeIcon={Close}
            >
              <div className={styles.text} ref={modalRef}>
                <MarkdownWrapper>
                  {card.text}
                </MarkdownWrapper>
              </div>
            </Wrapper>
          </div>
        </div>
      ))}
    </div>
  );
};

Bingo.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Bingo);
