import { get } from 'core/libs/lodash';
import pluralize from 'core/utils/pluralize';


/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person swagger-схемы
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */
export function getAvatar(person = {}, version = 'original') {
  const path = [
    'attributes',
    'avatar',
    'attributes',
    'versions',
    version,
  ];

  const defaultAvatar = {
    rel_url: require('site/images/avatar.png'),
    width: 400,
    height: 400,
  };

  return get(person, path, defaultAvatar);
}


/**
 * Утилита форматирует строку "продолжительность фильма / сериала".
 *
 * @param {Object} movieMeta
 * @param {string|number} movieMeta.duration - длительность фильма
 * @param {number} movieMeta.seasonsCount - длительность сериала в сериях
 * @param {number} movieMeta.episodesCount - количество серий в многосерийном фильме
 * @returns {string}
 */
export const getMovieDurationWithSuffix = (movieMeta = {}) => {
  const { duration, seasonsCount, episodesCount } = movieMeta;

  if (duration) {
    if (typeof duration === 'number') {
      return Math.floor(duration / 60) + '\u00A0мин.';
    }

    return duration;
  }

  if (seasonsCount) {
    return `${seasonsCount}\u00A0${pluralize(seasonsCount, ['сезон', 'сезона', 'сезонов'])}`;
  }

  if (episodesCount) {
    return `${episodesCount}\u00A0${pluralize(episodesCount, ['серия', 'серии', 'серий'])}`;
  }

  return '';
};


/**
 *
 * @param {string} str строка с символами `\n`
 * @param {number} itemsLimit количество элементов, которые нужно оставить в
 *                            строке-результате
 * @param {*} overflowSuffix суффикс, который надо добавить строке, если кол-во
 *                           элементов превысило itemsLimit
 * @returns {string} строка с элементами, разделёнными запятыми
 */
export const replaceNewLineWithComma = (str, itemsLimit, overflowSuffix = '') => {
  if (typeof str !== 'string') return '';

  const items = str.split('\n', itemsLimit);
  const suffix = items.length > itemsLimit ? overflowSuffix : '';

  return items.join(', ') + suffix;
};

export function imageVersionExists(cover) {
  if (cover && cover.width && cover.height && cover.rel_url) return true;
  return false;
}

export const getHardCodeTopic = fields => {
  const {
    headline,
    link,
    published_at: publishedAt,
    coverType = 'original',
    image,
  } = fields;

  return {
    id: 'promo',
    type: 'topic',
    attributes: {
      headline,
      link,
      published_at: publishedAt,
    },
    ...image && {
      relationships: {
        image: {
          data: {
            attributes: {
              versions: {
                [coverType]: {
                  rel_url: image,
                  ...coverType === 'square' && {
                    width: 240,
                    height: 240,
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};
