import Mascot from 'site/components/Mascot/LazyMascot';

import styles from './index.styl';

export default function ErrorPageHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.mascot}>
        <Mascot type='scared' width={60} />
      </div>
      <div>Ошибка 404</div>
    </div>
  );
}
