import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import { withBreakpoint } from 'core/components/breakpoint';

import Feed from 'core/components/Feed';
import { Indent, PageIndent, NegativeMobile } from 'core/components/Wrappers';

import CardVertical from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';
import {
  CompareCardL,
  CompareCardAccentedL,
  CompareCardVerticalS,
  CompareCardAccentedVerticalM,
  CompareCardVerticalM,
} from 'site/cards/CompareCard';

import ListDivider from 'site/components/ListDivider';
import {
  WideWrapper,
  RubricAccent,
} from 'site/components/Wrappers';

const CardMobile = bindProps({ imageMaxWidth: 800 })(CardVertical);
const CardDesktop = bindProps({ imageMaxWidth: 437 })(CardVertical);
const CardDesktopBig = bindProps({
  size: 'xl',
  imageWrapperWidth: '40%',
  imageRatio: 16 / 9,
  imageMaxWidth: 548,
})(CardHorizontal);
const HeroCardCompare = (props) => (
  <NegativeMobile>
    <CompareCardAccentedVerticalM {...props} />
  </NegativeMobile>
);

const cardsMap = {
  compare: {
    desktop: {
      firstBigCard: CompareCardAccentedL,
      secondBigCard: CompareCardL,
      firstThreeCard: CompareCardVerticalS,
      firstFourCard: CompareCardVerticalS,
    },
    mobile: {
      firstCard: HeroCardCompare,
      secondCard: CompareCardVerticalM,
    },
  },
  publications: {
    desktop: {
      firstBigCard: CardDesktopBig,
      secondBigCard: CardDesktopBig,
      firstThreeCard: CardDesktop,
      firstFourCard: CardDesktop,
    },
    mobile: {
      firstCard: CardMobile,
      secondCard: CardMobile,
    },
  },
};


function PublicationsFeed(props) {
  const {
    content,
    isDesktop,
    isMobile,
    compareRubric,
    breakpoint,
    showFirstTopic,
  } = props;


  const cardsType = compareRubric ? cardsMap.compare[breakpoint] : cardsMap.publications[breakpoint];

  const {
    firstBigCard: FirstBigCard,
    secondBigCard: SecondBigCard,
    firstThreeCard: FirstThreeCard,
    firstFourCard: FirstFourCard,
    firstCard: FirstCard,
    secondCard: SecondCard,
  } = cardsType;

  if (!content.length) {
    return null;
  }

  const spacing = isDesktop ? 30 : 20;

  const firstTopic = content[0];

  if (isMobile) {
    return (
      <>
        {showFirstTopic && (
          <RubricAccent>
            <PageIndent>
              <Indent top={spacing} bottom={spacing}>
                <FirstCard content={firstTopic} />
              </Indent>
            </PageIndent>
          </RubricAccent>
        )}
        <PageIndent>
          <Indent top={spacing} bottom={spacing}>
            <Feed
              interitemSpacing={spacing}
              card={SecondCard}
              content={content.slice(1)}
            />
          </Indent>
        </PageIndent>
      </>
    );
  }

  const offset = !showFirstTopic ? 0 : 1;
  const firstThree = content.slice(offset, offset + 3);
  const firstFour = content.slice(offset + 3, offset + 7);
  const secondBigTopic = content[offset + 7];
  const secondThree = content.slice(offset + 8, offset + 11);
  const secondFour = content.slice(offset + 11);

  return (
    <>
      {firstTopic && showFirstTopic && (
        <RubricAccent>
          <WideWrapper>
            <Indent top={spacing} bottom={spacing}>
              <FirstBigCard content={firstTopic} />
            </Indent>
          </WideWrapper>
        </RubricAccent>
      )}
      <WideWrapper>
        {firstThree.length > 0 && (
          <Indent top={spacing} >
            <Feed
              interitemSpacing={spacing}
              card={FirstThreeCard}
              content={firstThree}
              columns={3}
              grid
            />
          </Indent>
        )}
        {firstFour.length > 0 && (
          <Indent top={spacing}>
            <Feed
              interitemSpacing={spacing}
              card={FirstFourCard}
              content={firstFour}
              columns={4}
              grid
            />
          </Indent>
        )}
        {secondBigTopic && (
          <>
            <ListDivider indent={spacing} />
            <SecondBigCard content={secondBigTopic} />
            <ListDivider indent={spacing} />
          </>
        )}
        {secondThree.length > 0 && (
          <Indent top={spacing} >
            <Feed
              interitemSpacing={spacing}
              card={FirstThreeCard}
              content={secondThree}
              columns={3}
              grid
            />
          </Indent>
        )}
        {secondFour.length > 0 && (
          <Indent top={spacing}>
            <Feed
              interitemSpacing={spacing}
              card={FirstFourCard}
              content={secondFour}
              columns={4}
              grid
            />
          </Indent>
        )}
      </WideWrapper>
      <Indent bottom={spacing} />
    </>
  );
}

PublicationsFeed.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  compareRubric: PropTypes.bool,
  breakpoint: PropTypes.string,
  showFirstTopic: PropTypes.bool,
};

PublicationsFeed.defaultProps = {
  showFirstTopic: true,
};

export default withBreakpoint(PublicationsFeed);
