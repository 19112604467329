import PropTypes from 'prop-types';
import cx from 'classnames';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import Mascot from 'site/components/Mascot/LazyMascot';

import styles from './index.styl';

function GradientRoundControl(props) {
  const {
    theme,
    handleClick,
    icon: Icon,
    className,
    withMascot,
    classNameMascot,
  } = props;

  return (
    <div className={cx(styles.control, className)} onClick={handleClick}>
      <style jsx>{`
        .${styles.control}
          &:before
            background ${theme.colors.gradient}
      `}</style>
      <div className={styles.content}>
        <Icon width={50} height={50} />
      </div>
      {withMascot &&
        <Mascot
          className={cx(styles.mascot, classNameMascot)}
          type='hanging'
          width={160}
        />}
    </div>
  );
}

GradientRoundControl.propTypes = {
  /** Обработчик нажатия на кнопку */
  handleClick: PropTypes.func,
  /** Класс для дополнительной стилизации */
  className: PropTypes.string,
  /** Иконка, отображаемая в кнопке */
  icon: PropTypes.func.isRequired,
  /** Отображать ли маскот */
  withMascot: PropTypes.bool,
  /** Имя класса для маскота */
  classNameMascot: PropTypes.string,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      gradient
    }
  }`),
};

GradientRoundControl.propTypes = {
  handleClick: () => null,
};

export { GradientRoundControl as StorybookComponent };

const ComponentWithHOCs = withTheme(GradientRoundControl);
ComponentWithHOCs.displayName = 'GradientRoundControl';

export default ComponentWithHOCs;
