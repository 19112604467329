import image1 from './images/1.jpg';
import image2 from './images/2.jpg';
import image3 from './images/3.jpg';
import image4 from './images/4.jpg';
import image5 from './images/5.jpg';
import image6 from './images/6.jpg';
import image7 from './images/7.jpg';
import image8 from './images/8.jpg';
import image9 from './images/9.jpg';
import image10 from './images/10.jpg';
import image11 from './images/11.jpg';
import image12 from './images/12.jpg';
import image13 from './images/13.jpg';
import image14 from './images/14.jpg';
import image15 from './images/15.jpg';


const cards = [
  {
    image: image1,
    text: '«Боб Дилан: Никому не известный» станет лучшим фильмом года. Байопик фолк- и рок-легенды от Джеймса Мэнголда кажется самым компромиссным выбором для академиков на фоне развлекательного («Злая») и серьезного («Бруталист») кино из категории. На его победу ставят многие индустриальные медиа.',
  },
  {
    image: image2,
    text: '«Мальчишки из „Никеля“» РаМелла Росса не получат ни одной награды. Экранизация романа Колсона Уайтхеда про двух темнокожих подростков в детской колонии уступит байопику Боба Дилана в главной номинации, а в категории «Лучший адаптированный сценарий» драму Росса обойдет «Конклав» Эдварда Бергера.',
  },
  {
    image: image3,
    text: 'Деми Мур («Субстанция») получит награду за лучшую женскую роль. После трогательной <a href="https://www.youtube.com/watch?v=9SvW3XJP5Ic" target="blank">речи</a> Мур на «Золотом глобусе» и недавнего <a href="/news/zvezda-emilii-peres-karla-sofiya-gaskon-izvinilas-za-svoi-protivorechivye-posty-02-02-2025" target="blank">скандала</a> с постами в соцсетях Карлы Софии Гаскон («Эмилия Перес») у актрисы есть все шансы на долгожданный успех. Главная соперница — Фернанда Торрес («Я всё еще здесь»)',
  },
  {
    image: image4,
    text: '«Анора», представленная в шести номинациях, получит статуэтку только за оригинальный сценарий. Главным конкурентом Шона Бэйкера остается Джесси Айзенберг («Настоящая боль»). Впрочем, невозможно предугадать, какая история произведет большее впечатление: о русских в Америке или об американцах в Польше.',
  },
  {
    image: image5,
    text: 'Реакция Юры Борисова на итоги обязательно завирусится. В сердцах соотечественников актер уже стал победителем. Но, увы, главными претендентами на триумф в категории «Лучший актер второго плана» называют Кирана Калкина («Настоящая боль») и Эдварда Нортона («Боб Дилан: Никому не известный»).',
  },
  {
    image: image6,
    text: '«Эмилия Перес» получит награду только за оригинальную песню <em>El Mal</em>. Мюзикл Жака Одиара, получивший четыре награды на «Золотом глобусе», был главным фаворитом, но теперь оказался в аутсайдерах после шквала критики в соцсетях и скандала с Карлой Софией Гаскон.',
  },
  {
    image: image7,
    text: '«Оскар» пройдет под эгидой благотворительного «телемарафона», направленного на сбор средств пострадавшим от пожаров в Лос-Анджелесе. Никаких жарких эпизодов не будет. С другой стороны, на недавней церемонии вручения премии «Грэмми» Канье Уэст и Бьянка Сенсори <a href="https://variety.com/2025/music/news/kanye-west-wife-bianca-censori-naked-grammys-red-carpet-1236294350/" target="blank">не постеснялись</a> эпатировать публику.',
  },
  {
    image: image8,
    text: 'Брэйди Корбет — лучший режиссер. Эта категория традиционно считается самой объективной, так как в ней награждают за художественные решения, а не за соответствие фильма общему настроению. Корбету и его «Бруталисту» неожиданно может бросить вызов Корали Фаржа («Субстанция») или Шон Бэйкер с «Анорой».',
  },
  {
    image: image9,
    text: 'Награду за лучшую женскую роль второго плана получит Изабелла Росселлини («Конклав»). Для великой 72-летней актрисы, снимавшейся в фильмах  Дэвида Линча, Джона Шлезингера и Гая Мэддина, это первая номинация на «Оскар». Кажется, это идеальный шанс для Американской академии исправиться.',
  },
  {
    image: image10,
    text: 'Тимоти Шаламе, сыгравший Боба Дилана, станет лучшим актером. Если же его опередит Эдриан Броуди («Бруталист»), то у самого молодого двукратного номинанта на премию «Оскар» есть все шансы получить приз зрительских симпатий в интернете за самый яркий наряд или за исполнение <em>Like a Rolling Stone</em> Дилана на церемонии. ',
  },
  {
    image: image11,
    text: 'Один из победителей скажет на сцене: «Я не готовил свою речь заранее». Надеемся, что это будет Юра Борисов со статуэткой за лучшего актера второго плана в руках.',
  },
  {
    image: image12,
    text: 'Фанаты мюзикла «Злая: Сказка о ведьме Запада» начнут сходить с ума и кричать «Бу-у-у!» из зала, если фильм не победит в главной категории. Это будет первый случай, когда освистывания из зала будут связаны не с политической речью на сцене «Долби».',
  },
  {
    image: image13,
    text: 'Ведущий Конан О’Брайен, известный аккуратностью и вежливостью, не будет шутить про Камалу Харрис и Дональда Трампа. Зато один из активистов-победителей затронет тему «правого поворота» Америки или вспомнит, что Сильвестр Сталлоне, Мэл Гибсон и Джон Войт стали посланниками президента в Голливуде. ',
  },
  {
    image: image14,
    text: 'Уилл Смит придет на «Оскар», несмотря на запрет участия в церемониях на десять лет после инцидента с Крисом Роком в 2022 году. ',
  },
  {
    image: image15,
    text: 'В Сети снова будут обсуждать, что секция «Память», посвященная ушедшим деятелям киноиндустрии, организована неуважительно. В прошлом году у многих вызвало недоумение исполнение песни <em>Time to Say Goodbye</em>, также некоторые <a href="https://variety.com/2024/film/awards/oscars-in-memoriam-left-out-1235926039/" target="blank">возмутились</a>, что актер Лэнс Реддик удостоился лишь текстового упоминания.',
  },
];

export default cards;
