import { useContext } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import Divider from 'core/components/Divider';

import { MoviesContext } from 'site/components/MoviesProvider';

import { TABLE_OF_CONTENTS_TITLE } from 'site/constants';


function TableOfContents({ theme }, { amp }) {
  const { movies, isLoading } = useContext(MoviesContext);

  if (isLoading || !movies || movies.length < 1) return null;

  return (
    <nav className='contentsTable'>
      <style jsx>{`
        .link
          color ${theme.colors.accent}
          &:hover
            color ${theme.colors.primary}
      `}</style>
      {/*
        *Стили выносить не нужно,
        *компонент используется в AMP
      */}
      <style jsx>
        {`
          .contentsTable
            margin-bottom 20px

          .title
            font-size 22px
            margin-bottom 20px

          .list
            margin 0
            padding 0

          .item
            margin-bottom 20px
            list-style none

          .link
            text-decoration none

          .itemNumber
            text-decoration none

          .itemText
            text-decoration underline dashed
            text-underline-offset 0.2em
            &:hover
              cursor pointer
              text-decoration none
        `}
      </style>
      <div className='title'>{TABLE_OF_CONTENTS_TITLE}</div>
      <ol className='list'>
        {movies.map((movie, index) => {
          const displayIndex = index + 1;
          return movie && (
            <li className='item' key={index}>
              <a
                className='link'
                {...amp ? {
                  'data-href': displayIndex,
                } : {
                  href: `#${displayIndex}`,
                }}
              >
                <span className='itemNumber'>{`${displayIndex}. `}</span>
                <span className='itemText'>{movie.attributes.headline}</span>
              </a>
            </li>
          );
        })}
      </ol>
      <Divider />
    </nav>
  );
}

TableOfContents.propTypes = {
  theme: PropTypes.object,
};

TableOfContents.contextTypes = {
  amp: PropTypes.bool,
};

export default withTheme(TableOfContents);
