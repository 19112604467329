import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TopicContent from 'core/components/TopicContent';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { SELECTIONS_SLUG, TABLE_OF_CONTENTS_TITLE } from 'site/constants';

import Link from 'site/components/Link';
import { QuizWrapper } from 'site/components/Wrappers';
import Bingo from 'site/components/Bingo';
import { MoviesContext } from 'site/components/MoviesProvider';

import TableOfContents from './TableOfContents';

// eslint-disable-next-line import/no-namespace
import * as siteWidgets from 'site/widgets';

import styles from './index.styl';

const additionalContent = [
  {
    before: 500,
    after: 0,
    showOnlyOnce: true,
    renderer: Bingo,
  },
];

const blocks = Object.keys(siteWidgets).reduce((result, widgetName) => {
  const blockName = widgetName[0].toLowerCase() + widgetName.slice(1);
  const Widget = siteWidgets[widgetName];

  result[blockName] = (block, parentProps, ctx) => (
    <Widget
      block={block}
      parentProps={parentProps}
      ctx={ctx}
    />
  );

  return result;
}, {});

const bodyMobileScopedStyles = resolveScopedStyles(
  <scope>
    <style jsx global>{`
      body
        overflow-x hidden
    `}</style>
  </scope>
);

function SiteTopicContent(props) {
  const {
    rubric,
    backgroundImage,
    isQuiz,
    isHasContents,
    isMobile,
    topic: {
      id: topicId,
    },
  } = props;

  const { widgets } = useContext(MoviesContext);

  const isSelection = rubric.slug === SELECTIONS_SLUG;
  const OuterWrapper = isQuiz ? QuizWrapper : Fragment;
  const isKalimbaTopic = topicId === 'cbe8c19f-5050-40e2-ad36-74edf9e5edc4';
  const isBingoTopic = topicId === 'b0249626-02f3-4107-8c6d-2bb07913b2b8';

  return (
    <OuterWrapper {...isQuiz && { backgroundImage }}>
      <div className={cx(styles.topicContent, isKalimbaTopic && styles.kalimba)}>
        {isHasContents && isSelection &&
          <TableOfContents />
        }
        <TopicContent
          tableOfContentsTitle={TABLE_OF_CONTENTS_TITLE}
          content={widgets}
          interitemSpacing={isMobile ? 20 : 30}
          blocks={blocks}
          disableMultipage
          disableDefaultNavigation={isSelection}
          /* свойства для виджетов */
          rubric={rubric}
          markdownComponents={{
            a({ children, href }) {
              return <Link to={href}>{children}</Link>;
            },
          }}
          {...isBingoTopic && { desktopAds: additionalContent, mobileAds: additionalContent }}
        />
      </div>
      {isKalimbaTopic && isMobile && <bodyMobileScopedStyles.styles />}
    </OuterWrapper>
  );
}

SiteTopicContent.propTypes = {
  rubric: PropTypes.object,
  backgroundImage: PropTypes.object,
  isHasContents: PropTypes.bool,
  isQuiz: PropTypes.bool,
  isMobile: PropTypes.bool,
  topic: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default withBreakpoint(SiteTopicContent);
