import palette from './palette';

const colors = {
  primary: palette.black,
  content: palette.white,
  divider: palette.gray300,
  timeTextColor: palette.gray500,
  hoverHeadlineColor: '#E8BC5D',
  gradient3: 'radial-gradient(111.44% 147.01% at 3.56% 0.01%, #FEDC53 0%, #E8BC5D 100%)',
  gradient3ButtonHoverColor: 'radial-gradient(130.82% 170.9% at 3.56% 0.01%, #FFF0B4 0%, #FF9F0E 100%)',
};

const button = {
  oscar: {
    idle: {
      color: '#000',
      background: colors.gradient3,
      border: 'none',
    },

    hover: {
      color: '#000',
      background: colors.gradient3ButtonHoverColor,
      border: 'none',
    },

    active: {
      color: '#000',
      background: colors.gradient3ButtonHoverColor,
      border: 'none',
    },

    disabled: {
      color: colors.grey1,
      background: 'transparent',
      border: `1px solid ${colors.grey1}`,
    },
  },
};

const paginatron = {
  btnType: 'oscar',
};

export default {
  colors,
  controls: {
    button,
    paginatron,
  },
};
