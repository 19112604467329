import { createContext } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import { VOID_QUERY } from 'core/site/constants';

import topicRelationships from 'site/pages/topic/topicRelationships';

import { SELECTIONS_SLUG } from 'site/constants';


export const MoviesContext = createContext({});

export default function MoviesProvider(props) {
  const {
    topic,
    children,
  } = props;

  const {
    rubric,
    content: {
      widgets: initialWidgets,
    },
  } = topicRelationships(topic);

  const teaserTopicIds = initialWidgets
    .filter(widget => widget.type === 'smartTeaser')
    .map(teaser => teaser.attributes.teaser_params.topic_id);

  const queryParams = teaserTopicIds?.length
    ? topicsQuery({
      topic_type: 'movie',
      fields: 'headline,source_url',
      include: 'image,movie_meta',
      ids: teaserTopicIds.join(','),
      renderError: () => [],
    })
    : VOID_QUERY;

  const {
    data: movies = [],
    isLoading,
  } = useRequest(queryParams);

  const positionSyncedMovies = teaserTopicIds?.map(id => {
    return movies.find(movie => id === movie.id) || null;
  });

  const widgets = [...initialWidgets];
  const moviesData = {};
  const isSelection = rubric?.slug === SELECTIONS_SLUG;

  const teasersAndBreaks = widgets.filter(({ type }) =>  ['pageBreak', 'smartTeaser'].includes(type));
  /**
   * Редакции неудобно добавлять пустой виджет "Разрыв страницы" в конце топика
   * для того, чтобы у последнего фильма отображалась кнопка "Смотреть".
   * Делаем это программно.
   */
  if (isSelection) {
    const lastSmartTeaserIndex = widgets.map(({ type }) => type).lastIndexOf('smartTeaser');
    const hasPageBreak = widgets.slice(lastSmartTeaserIndex).some(({ type }) => type === 'pageBreak');

    if (!hasPageBreak) {
      widgets.push({
        'id': 'pageBreak-last',
        'type': 'pageBreak',
        'attributes': {
          'title': '',
          'caption': '',
        },
      });
    }
  }

  // Собираем инфу для содержания, pageBreak и SmartTeaser,
  // и формируем ссылки на фильм для кнопки "смотреть"

  let movie = null;
  let pageBreakNumber = 1;

  const processPageBreak = (widget) => {
    const nextTeaserIndex = teasersAndBreaks.findIndex(({ id }) => id === widget.id) + 1;
    const nextTeaser = teasersAndBreaks[nextTeaserIndex];
    const hideNumber = nextTeaser?.type !== 'smartTeaser';

    moviesData[widget.id] = {
      number: pageBreakNumber,
      movie,
      hideNumber,
    };
    movie = null;
    pageBreakNumber++;
  };

  const processSmartTeaser = (widget) => {
    const teaserTopicId = widget.attributes.teaser_params.topic_id;
    const widgetMovie = movies.find(movieItem => movieItem?.id === teaserTopicId);
    moviesData[widget.id] = {
      movie: widgetMovie,
    };
    movie = widgetMovie;
  };

  for (const widget of widgets) {
    if (widget.type === 'pageBreak') {
      processPageBreak(widget);
    }

    if (widget.type === 'smartTeaser') {
      processSmartTeaser(widget);
    }
  }

  return (
    <MoviesContext.Provider
      value={{
        widgets,
        movies: positionSyncedMovies,
        moviesData,
        isLoading,
      }}
    >
      {children}
    </MoviesContext.Provider>
  );
}

MoviesProvider.propTypes = {
  topic: PropTypes.object,
};
