import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';

import InfiniteTopic from 'core/components/InfiniteTopic';
import GameCompare from 'core/components/GameCompare';
import Topic from 'core/components/Topic';
import { Indent, PageIndent } from 'core/components/Wrappers';
import AdWrapper from 'core/components/Ad/AdWrapper';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { isCompareTopic as isCompareTopicCore } from 'core/components/GameCompare/utils';

import Boroda from 'site/components/Boroda';
import { NarrowWrapper } from 'site/components/Wrappers';
import MoviesProvider from 'site/components/MoviesProvider';
import { Inpage } from 'site/components/Ads';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import TopicSkeleton from './Skeleton';
import Mascot from './Mascot';

import topicRelationships from './topicRelationships';

import styles from './index.styl';


function TopicRender(props) {
  const {
    topic,
    isMobile,
    withInfinityScroll,
  } = props;

  const {
    attributes: {
      topic_type: topicType,
      is_has_contents: isHasContents,
    },
  } = topic;

  const {
    rubric,
    background: {
      asset: {
        attributes: {
          versions: {
            original: backgroundImage,
          },
        },
      },
    },
  } = topicRelationships(topic);

  const isQuiz = topicType === 'quiz';
  const isCompareTopic = isCompareTopicCore(topicType);

  const WidthWrapper = isQuiz ? Fragment : NarrowWrapper;

  return (
    <PageIndent>
      <div className={styles.topicWrapper}>
        {!isMobile && (
          <Mascot />
        )}
        <WidthWrapper>
          {!isQuiz && (
            <TopicHeader content={topic} isCompareTopic={isCompareTopic} />
          )}
          {!isCompareTopic && (
            <>
              <TopicContent
                rubric={rubric}
                backgroundImage={backgroundImage}
                isHasContents={isHasContents}
                isQuiz={isQuiz}
                topic={topic}
              />
              <TopicFooter content={topic}  isQuiz={isQuiz} />
            </>
          )}
          {isCompareTopic && (
            <GameCompare content={topic} />
          )}
          <Indent bottom={isMobile ? 16 : 20}>
            <AdWrapper top={10}>
              <Inpage />
            </AdWrapper>
          </Indent>
        </WidthWrapper>
      </div>
      <Indent
        left={isMobile ? -SIDE_INDENT_MOBILE : -SIDE_INDENT}
        right={isMobile ? -SIDE_INDENT_MOBILE : -SIDE_INDENT}
        {...withInfinityScroll && {
          bottom: isMobile ? 20 : 40,
          top: isMobile ? 0 : 9,
        }}
        {...!withInfinityScroll && {
          top: isMobile ? 83 : 55,
        }}
      >
        <Boroda topic={topic} onlyMovies={withInfinityScroll} />
      </Indent>
    </PageIndent>
  );
}

TopicRender.propTypes = {
  topic: modelPropTypes(topicAttributes).isRequired,
  movies: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  moviesAreLoading: PropTypes.bool,
  enhancedTopic: PropTypes.shape({
    infinityIndex: PropTypes.number,
  }),
  withInfinityScroll: PropTypes.bool,
};

const EnhancedTopicRender = withBreakpoint(TopicRender);

function TopicPage(props) {
  const {
    data: topic,
    isLoading,
  } = useRequest(topicQuery(props));

  if (isLoading) return <TopicSkeleton />;

  const withInfinityScroll = topic.attributes.topic_type === 'news';
  const TopicWrapper = withInfinityScroll ? InfiniteTopic : Topic;

  return (
    <TopicWrapper content={topic} {...withInfinityScroll && { topic_type: 'news' }}>
      {(topicContent, enhancedTopic) => {
        return (
          <MoviesProvider topic={topicContent}>
            <EnhancedTopicRender
              topic={topicContent}
              enhancedTopic={enhancedTopic}
              withInfinityScroll={withInfinityScroll}
            />
          </MoviesProvider>
        );
      }}
    </TopicWrapper>
  );
}

export default TopicPage;
