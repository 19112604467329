import color from 'core/libs/color';

import palette from './palette';

const colors = {
  altHeadline: palette.black,
  hoverHeadlineColor: palette.electroPurple500, // цвет хедлайна при наведении на карточку
  rubricColor: palette.electroPurple500,
  timeTextColor: palette.gray500,
  tagsTextColor: palette.gray500,
  linkColor: palette.electroPurple500, // цвет ссылок
  chips: palette.gray900,
  layout: palette.white,
  divider: palette.gray300,
  placeholder: palette.gray300,
  active1: palette.electroPurple500,
  active2: palette.electroPurple500,
  accent: palette.electroPurple500,
  accent2: palette.mauve,
  primary: palette.black,
  content: palette.white,
  gradient: `radial-gradient(43% 110% at 3.5% 0%, ${palette.electroPurple500} 0%, ${palette.violet800} 100%)`,
  gradient2: 'linear-gradient(-45deg, #000000, #30017B 30%, #000000)',
  input: palette.white,
  grey1: palette.gray300,
  grey2: palette.gray600,
  grey3: palette.gray900,
  playlistBgColor: palette.skyBlue50,
  primaryButtonHoverColor: palette.electroPurple100,
  hoverGradient: `radial-gradient(100% 299% at 40.5% 0%, ${palette.electroPurple500} 30%, ${palette.violet800} 100%)`,
  gray1: palette.gray300,
  gray2: palette.gray600,
  grey4: palette.gray100,
  grey5: palette.gray200,
  grey6: palette.gray400,
  grey7: palette.gray700,
  grey8: palette.gray800,
  hoverButton: palette.electroPurple900,
  caption: palette.black,
  credits: palette.gray600,
  error: 'red',
};

const gradientButton = {
  type: 'filled',
  background: colors.gradient,
  hoverBackground: colors.hoverGradient,
};

const popcornButton = {
  popcornOffset: '1px',

};

const button = {
  primary: {
    idle: {
      color: colors.content,
      background: colors.gradient,
      border: 'none',
    },

    hover: {
      color: colors.primaryButtonHoverColor,
      background: colors.gradient,
      border: 'none',
    },

    active: {
      color: colors.primaryButtonHoverColor,
      background: colors.gradient,
      border: 'none',
    },

    disabled: {
      color: colors.grey1,
      background: 'transparent',
      border: `1px solid ${colors.grey1}`,
    },
  },

  tab: {
    idle: {
      color: colors.content,
      background: colors.grey2,
      border: 'none',
    },

    hover: {
      color: colors.content,
      background: color(colors.grey2).alpha(0.7).string(),
      border: 'none',
    },

    active: {
      color: colors.content,
      background: colors.grey2,
      border: 'none',
    },

    disabled: {
      color: color(colors.content).alpha(0.9).string(),
      background: color(colors.grey2).alpha(0.7).string(),
      border: 'none',
    },
  },
};

export default {
  colors,
  controls: {
    gradientButton,
    popcornButton,
    button,
  },
};
