import stylesAtomsFactory from 'core/themes/styles-atoms';
import stylesAtomsMobileFactory from 'core/themes/styles-atoms-mobile.js';

import baseTheme from './base';
import baseMobileTheme from './mobile';

import darkProperties from './dark';
import lightProperties from './light';

import oscarTheme from './oscar';

import mamontyTheme from './mamonty';

const base = atoms => stylesAtomsFactory(baseTheme(atoms));

export const light = base(lightProperties);
export const dark = base(darkProperties);

export const lightMobile = stylesAtomsMobileFactory(light, baseMobileTheme({ colors: lightProperties.colors }));
export const darkMobile = stylesAtomsMobileFactory(dark, baseMobileTheme({ colors: darkProperties.colors }));

export const mamontyDarkMobile = stylesAtomsMobileFactory(dark, mamontyTheme({ colors: darkProperties.colors }));

export const oscar = base(oscarTheme);
export const oscarMobile = stylesAtomsMobileFactory(light, baseMobileTheme(oscarTheme));
