import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { rawTopicsQuery } from 'core/queries/listPage';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';
import withTheme from 'core/components/theme';
import ThemeSwitch from 'core/components/ThemeSwitch';
import ListPage from 'core/components/ListPage';

import { WideWrapper } from 'site/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';
import Pagination from 'site/components/Pagination';

import oscar from './image/oscar.png';

import styles from './index.styl';

function Oscars(props) {
  const {
    location,
    history,
    theme,
    isDesktop,
  } = props;

  const limit = isDesktop ? 15 : 8;

  const { data: rawData, isLoading } = useRequest(rawTopicsQuery({
    location,
    history,
    limit: limit,
    list: 'oscar2025',
    sort: 'list',
    include: 'image',
  }));

  return (
    <ThemeSwitch name='oscar'>
      <>
        <style jsx>{`
          .${styles.content}
            color ${theme.colors.content}

          .${styles.figure}
            font 900 48px/56px ${theme.fonts.display}

          .${styles.text}
            font 400 24px/28px ${theme.fonts.display}
            :global(.mobile) &
              font 400 20px/25px ${theme.fonts.display}

          .span
            font 400 24px/28px ${theme.fonts.display}

          .date
            font 400 16px/20px ${theme.fonts.display}
            :global(.mobile) &
              font 400 14px/18px ${theme.fonts.display}
        `}</style>
        <div className={styles.container}>
          <div className={styles.content}>
            <img
              src={oscar}
              className={styles.image}
              alt='Оскар'
            />
            <div className={styles.figure}>97<span className='span'> -я</span></div>
            <div className={styles.text}>церемония вручения наград премии &laquo;Оскар&raquo; {!isDesktop && <br />}в&nbsp;Лос-Анджелесе</div>
            <div className='date'>В ночь со 2 на 3 марта в Лос-Анджелесе пройдет 97-я церемония вручения наград премии «Оскар». Подготовьтесь к важному событию в мире в кино вместе с нами.</div>
          </div>
        </div>
        <Indent top={isDesktop ? -105 : -90} bottom={isDesktop ? 50 : 20}>
          <ListPage
            rawData={rawData}
            title='Премия «Оскар-2025»: Все, что нужно знать о церемонии и номинантах'
            description='Гид по кинопремии: дата и место вручения премии, номинанты и лучшие картины. Собрали все, что вы хотели знать про церемонию вручения «Оскар-2025», в одном материале.'
            isLoading={isLoading}
            limit={limit}
            pagination={paginationProps => (
              <WideWrapper>
                <Pagination {...paginationProps} />
              </WideWrapper>
            )}
          >
            {({ content }) => (
              <RubricTopics
                topics={content}
                isLoading={isLoading}
                showFirstTopic={false}
              />
            )}
          </ListPage>
        </Indent>
      </>
    </ThemeSwitch>
  );
}

Oscars.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(withTheme(Oscars));
