import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { VOID_QUERY } from 'core/site/constants';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { Indent } from 'core/components/Wrappers';
import Feed from 'core/components/Feed';
import { SimpleLoader } from 'core/components/Loader';

import { WideWrapper } from 'site/components/Wrappers';

import Card, { CardHorizontalXS } from 'site/cards/CardHorizontal';


const CardWithImageDesktop = bindProps({
  size: 'xs',
  imageMaxWidth: 120,
  lockImageWidth: true,
  imageVersion: 'square',
})(Card);

const CardWithImageMobile = bindProps({
  size: 'xs',
  imageMaxWidth: 60,
  lockImageWidth: true,
  imageVersion: 'square',
})(Card);


function BorodaTopics(props) {
  const {
    isMobile,
    topic,
  } = props;

  const currentTopicId = topic?.content?.id;

  const {
    data: read = [],
    isLoading: readIsLoading,
  } = useRequest(currentTopicId ? topicsQuery({
    limit: isMobile ? 1 : 3,
    list: 'read',
    sort: 'list',
    include: 'image,rubric',
    excluded_ids: currentTopicId,
    renderError: () => [],
  }) : VOID_QUERY);

  const {
    data: news = [],
    isLoading: newsAreLoading,
  } = useRequest(topicsQuery({
    limit: isMobile ? 3 : 9,
    include: 'tags',
    topic_type: 'news',
    renderError: () => [],
  }));

  if (readIsLoading || newsAreLoading) return <SimpleLoader />;

  const hasNews = news.length > 0;
  const hasReadItems = read.length > 0;

  return (
    <WideWrapper>
      {hasNews && (
        <Indent bottom={isMobile ? 15 : 40}>
          <Feed
            card={CardHorizontalXS}
            content={news}
            interitemSpacing={30}
            columns={isMobile ? 1 : 3}
          />
        </Indent>
      )}
      {hasReadItems && (
        <Indent bottom={30}>
          <Feed
            card={isMobile ? CardWithImageMobile : CardWithImageDesktop}
            content={read}
            interitemSpacing={30}
            columns={isMobile ? 1 : 3}
          />
        </Indent>
      )}
    </WideWrapper>
  );
}

BorodaTopics.propTypes = {
  isMobile: PropTypes.bool,
  topic: modelPropTypes(topicAttributes),
};

export default withBreakpoint(BorodaTopics);
