import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import ReadAndWatchSkeleton from 'site/pages/main/ReadAndWatch/Skeleton';

function Skeleton({ breakpoint, isMobile }) {
  return (
    <Fragment>
      <ReadAndWatchSkeleton breakpoint={breakpoint} />
      <Indent top={isMobile ? 31 : 61} />
    </Fragment>
  );
}

Skeleton.propTypes = {
  breakpoint: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Skeleton);
